#about {
  padding: 20px;
}

#about h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

#about p {
  font-size: 16px;
  margin-bottom: 20px;
}
