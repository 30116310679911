html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  position: relative;
}

header h1 {
  margin: 0;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 10px;
}

nav ul li a {
  color: white;
  text-decoration: none;
}

nav ul li a:hover {
  text-decoration: underline;
}

footer {
  position: relative;
  width: 100%;
  height: 60px; 
  background: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}
#signInDiv {
  margin-bottom: 10px;
}

.header-right {
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
}

.google-signin-button, .make-staff-button {
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.google-signin-button:hover, .make-staff-button:hover {
  background-color: #357ae8;
}

.profile {
  position: relative;
  display: inline-block;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.profile:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.make-staff-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.make-staff-button:hover {
  background-color: #45a049;
}

form {
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

form div {
  margin-bottom: 15px;
}

form label {
  margin-bottom: 5px;
  font-weight: bold;
}

form input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #4285F4;
  color: white;
  cursor: pointer;
}

form button:hover {
  background-color: #357ae8;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
}

.content-wrapper {
  flex: 1;
  padding-bottom: 60px; /* Height of the footer */
  box-sizing: border-box;
}