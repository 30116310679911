#home {
  padding: 20px;
}

#home h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

#home p {
  font-size: 16px;
  margin-bottom: 20px;
}

#home button {
  padding: 10px 20px;
  font-size: 16px;
}
