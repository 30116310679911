.content-wrapper {
  padding: 20px;
}

.event-form {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.event-form h3 {
  margin-top: 0;
}

.event-form label {
  display: block;
  margin-bottom: 10px;
}

.event-form input,
.event-form textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.event-form button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.event-form button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}
