#privacy-policy {
    padding: 20px;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: auto;
  }
  
  #privacy-policy h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  #privacy-policy h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  #privacy-policy p, #privacy-policy ul {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  #privacy-policy ul {
    list-style-type: none; /* Remove the default bullet points */
    padding-left: 0;
  }
  
  #privacy-policy ul li {
    margin-left: 0;
  }
  
  #privacy-policy ul li::before {
    content: "• "; /* Add a custom bullet point */
    color: #333; /* Customize the bullet point color */
  }
  
  #privacy-policy ul ul {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  #privacy-policy ul ul li {
    list-style-type: none; /* Remove the default bullet points for nested lists */
  }
  
  #privacy-policy ul ul li::before {
    content: "◦ "; /* Add a custom bullet point for nested lists */
    color: #666; /* Customize the bullet point color for nested lists */
  }
  
  footer {
    text-align: center;
    padding: 10px 0;
    background-color: #333;
    color: #fff;
    margin-top: 20px;
  }
  
  footer p {
    margin: 0;
  }
  